import * as React from 'react';
import PI from '../../core/CoreModules';

const IHeader = () => {
  return (
    <PI.Box width="100%">
      <PI.Paper
        elevation={1}
        sx={{
          padding: '28px 32px',
        }}
      >
        <PI.Typography
          sx={{
            fontWeight: '600',
            fontSize: '32px',
            lineHeight: '40px',
          }}
        >
          Identification
        </PI.Typography>
      </PI.Paper>
    </PI.Box>
  );
};

export default IHeader;
