import * as React from 'react';
import { useDispatch } from 'react-redux';
import { checkAuth } from '../api/user';
import { AppDispatch } from '../redux/store';
import { useNavigate } from 'react-router-dom';
import PI from '../core/CoreModules';
import { setLoading } from '../redux/authSlice';
import { keyframes } from '@mui/material/styles';
import STLViewer from '../directive/Three';
import '../scss/bubble.scss';
import { HomeCards } from '../component/home-cards/HomeCards';
import HomeFooter from '../component/home-footer/HomeFooter';
import FAQSCom from '../component/faqs/FAQSCom';
import Logo from '../directive/Logo';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { SupportAction } from '../component/support-action/SupportAction';
import ParticleBackground from 'component/particles/Particlesbackground';
import { useThemeContext } from 'theme/ThemeContextProvider';
import { FAQS_DATA } from '../component/home-cards/const';
import { WishList } from 'component/wish-list/WishList';

const slideFromTop = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const HomeLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [currentText, setCurrentText] = React.useState<any>({});
  const [currentCardText, setCurrentCardText] = React.useState<any>({});
  const [isAnimating, setIsAnimating] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const lessMobile = useMediaQuery('(max-width:430px)');
  const { mode } = useThemeContext();

  const textArray = [
    { text: 'trade PI.', color: '#f4af47' }, // Pink
    { text: 'BUY PI.', color: '#66bb6a' }, // Green
    { text: 'SELL PI.', color: '#f44336' }, // Red
  ];

  const cardTestArray = [
    { text: 'Buy', color: '#66bb6a' }, // Green
    { text: 'Sell', color: '#f44336' }, // Red
  ];

  React.useEffect(() => {
    let index = 0;
    let c_index = 0;

    const intervalId = setInterval(() => {
      setCurrentText(textArray[index]);
      setCurrentCardText(cardTestArray[c_index]);
      index = (index + 1) % textArray.length;
      c_index = (c_index + 1) % cardTestArray.length;
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [dispatch, navigate]);

  React.useEffect(() => {
    // Trigger the animation when currentText changes
    setIsAnimating(true);
    const animationEndHandler = () => {
      setIsAnimating(false);
    };
    const currentTextElement = document.querySelector('.animated-text');
    if (currentTextElement) {
      currentTextElement.addEventListener('animationend', animationEndHandler);
    }
    return () => {
      if (currentTextElement) {
        currentTextElement.removeEventListener(
          'animationend',
          animationEndHandler
        );
      }
    };
  }, [currentText]);

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      const resp: any = await dispatch(checkAuth());
      if (resp && resp.success) {
        navigate('/dashboard');
      } else {
        console.log('Authentication failed');
      }
    } catch (err) {
      console.error('Error during authentication check:', err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSignInClick = (path: string): void => {
    navigate(path);
  };

  setTimeout(() => {
    setShow(true);
  }, 2000);

  return (
    <PI.Box
      sx={{
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // rowGap: { xs: '1pc', sm: '1.5pc', md: '2pc' },
        position: 'relative',
      }}
    >
      <PI.Box
        sx={{
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#6c3c8e',
          zIndex: 1,
          boxShadow:
            'inset 0 0 100px rgba(0, 0, 0, 0.5), 0 4px 8px rgba(0, 0, 0, 0.3)',
          height: { xs: '115vh', sm: '100vh', md: '95vh' },
          backgroundImage: `url(assets/bgGlob.png),
          linear-gradient(135deg, #8a348e 0%, #423f88 50%, #333 100%)
          `,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: {
            xs: 'top center', // For mobile
            sm: 'top right', // For tablet
            md: 'center right', // For desktop
          },
          backgroundSize: {
            xs: 'contain', // For mobile
            sm: 'contain', // For tablet
            md: 'auto', // For desktop
          },
          position: 'relative',
          px: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <PI.Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background:
              "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23FFFFFF' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23FFFFFF'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E\")",
            opacity: '0.1',
          }}
        />

        <div className={`circle${mode} xxlarge shade1 `}></div>
        <div className={`circle${mode} xlarge shade2`}></div>
        <div className={`circle${mode} large shade3`}></div>
        <div className={`circle${mode} medium shade4`}></div>
        <div className={`circle${mode} small shade5`}></div>

        <PI.Stack
          direction="row"
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={{ xs: 2, sm: 3, md: 4 }}
        >
          <PI.Stack direction="row" alignItems="center" spacing={1}>
            <Logo />
          </PI.Stack>
          <PI.Button
            variant="outlined"
            sx={{
              // color: '#ffffff',
              // borderColor: '#ffffff',
              fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
              // width: { xs: '85px', sm: '90px', md: '100px', lg: '120px' },
              // height: { xs: '35px', sm: '38px', md: '40px', lg: '42px' },
              zIndex: 1,
              backgroundImage:
                'linear-gradient(to right, #314755 0%, #26a0da  51%, #314755  100%)',
              margin: '10px',
              padding: '10px 25px',
              textAlign: 'center',
              textTransform: 'uppercase',
              transition: '0.5s',
              backgroundSize: '200% auto',
              color: 'white',
              boxShadow: '0 0 5px #eee',
              borderRadius: '50px',
              display: 'block',
              border: 'none',

              '&:hover': {
                backgroundPosition:
                  'right center' /* change the direction of the change here */,
                color: '#fff',
                textDecoration: 'none',
                border: 'none',
              },
            }}
            onClick={() => handleSignInClick('/login')}
          >
            SIGN IN
          </PI.Button>
          {/* <PI.Button onClick={() => setShow(true)}> Render</PI.Button> */}
        </PI.Stack>
        <PI.Stack
          direction="column"
          alignItems="center"
          width="100%"
          pl={{ xs: 0, sm: 2, md: 4 }}
          mt={5}
          zIndex={1}
        >
          <PI.Typography
            variant="h3"
            component="h5"
            fontWeight="bold"
            fontSize={{ xs: '34px', sm: '44px', md: '64px' }}
            color="#9E9E9E"
            sx={{
              zIndex: 1,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          >
            Pi Network Hub
          </PI.Typography>
          <PI.Typography
            variant="h5"
            component="p"
            fontWeight="bolder"
            sx={{
              display: lessMobile ? 'column' : 'flex',
              columnGap: '7px',
              textAlign: 'center',
              fontSize: { xs: '26px', sm: '32px', md: '38px' },
              // color: '#DEB071',
              color: '#f4af47',
              zIndex: 1,
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          >
            Your premier platform to
            <PI.Typography
              variant="h5"
              component="span"
              className="animated-text"
              fontWeight="bolder"
              sx={{
                animation: isAnimating
                  ? `${slideFromTop} 1s ease-out forwards`
                  : 'none',
                color: currentText.color,
                fontSize: { xs: '26px', sm: '32px', md: '38px' },
                zIndex: 1,
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                ml: 1,
              }}
            >
              {currentText.text}
            </PI.Typography>
          </PI.Typography>
        </PI.Stack>

        <PI.Stack
          width="100%"
          height="100%"
          direction={{ xs: 'column-reverse', md: 'row' }}
          justifyContent="space-between"
          p={{ xs: 0, sm: 3, md: 4 }}
          alignItems="center"
          spacing={{ xs: 4, md: 0 }}
        >
          <PI.Stack
            direction="column"
            width={{ xs: '100%', md: '50%' }}
            height="100%"
            justifyContent="space-evenly"
          >
            <PI.Stack direction="column" sx={{}} alignItems="center">
              <PI.Typography
                variant="h5"
                component="p"
                fontWeight="bolder"
                sx={{
                  fontSize: { xs: '34px', sm: '48px', md: '52px' },
                  color: '#f4af47',
                  zIndex: 1,
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                }}
              >
                Experience the first-ever marketplace dedicated to Pi
                transactions.
              </PI.Typography>
              <PI.Stack
                direction="row"
                alignItems="center"
                spacing={1}
                width="100%"
                justifyContent="center"
              >
                <PI.Button
                  variant="contained"
                  sx={{
                    width: '100%',
                    maxWidth: '200px',
                    borderWidth: '2px',
                    borderRadius: '50px',
                    zIndex: 1,
                    fontSize: { xs: '16px', sm: '18px', md: '22px' },
                    color: '#ffffff',
                    backgroundImage:
                      'linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673)',
                    boxShadow: '0 4px 15px 0 rgba(23, 168, 108, 0.75)',
                  }}
                  size={isMobile ? 'small' : 'medium'}
                  onClick={() => handleSignInClick('/trade')}
                >
                  BUY Pi
                </PI.Button>
                <PI.Button
                  variant="contained"
                  // color="error"
                  sx={{
                    width: '100%',
                    maxWidth: '200px',
                    borderWidth: '2px',
                    borderRadius: '50px',
                    zIndex: 1,
                    fontSize: { xs: '16px', sm: '18px', md: '22px' },
                    backgroundImage:
                      'linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f)',
                    boxShadow: '0 5px 15px rgba(242, 97, 103, .4)',
                  }}
                  size={isMobile ? 'small' : 'medium'}
                  onClick={() => handleSignInClick('/trade')}
                >
                  SELL Pi
                </PI.Button>
              </PI.Stack>
              {/* <PI.Button
                variant="outlined"
                sx={{
                  color: '#DEB071',
                  borderColor: '#DEB071',
                  borderWidth: '2px',
                  fontSize: { xs: '16px', sm: '18px', md: '22px' },
                  textTransform: 'capitalize',
                  fontWeight: 'bold',
                  mt: { xs: 1, sm: 1, md: 1 },
                  zIndex: 1,
                  width: '100%',
                  maxWidth: '410px',
                }}
                onClick={() => handleSignInClick('/login')}
              >
                Getting Started
              </PI.Button> */}
            </PI.Stack>
          </PI.Stack>
          <PI.Box
            width={{ xs: '100%', sm: '100%', md: '50%' }}
            // maxWidth={{ xs: 500, sm: 400, md: 500 }}
            height={{ xs: '45%', md: '80%' }}
            zIndex="1"
          >
            <STLViewer show={show} />
          </PI.Box>
        </PI.Stack>
      </PI.Box>
      <PI.Card
        sx={{
          width: '100%',
          height: { xs: '10vh', sm: '12vh', md: '15vh' },
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <PI.Typography
          variant="h3"
          component="h5"
          fontWeight="bold"
          fontSize={{ xs: '28px', sm: '44px', md: '64px' }}
          color="#9E9E9E"
          sx={{
            zIndex: 1,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          Trade anytime, anywhere
        </PI.Typography>
      </PI.Card>
      <PI.Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          rowGap: '4pc',
          p: { xs: 2, md: 4 }, // Responsive padding
          mt: { xs: 10, md: 15 },
          position: 'relative',
        }}
      >
        <ParticleBackground />
        <HomeCards />
      </PI.Box>
      <WishList />

      <FAQSCom list={FAQS_DATA} />
      <PI.Card
        sx={{
          width: '100%',
          height: { xs: '20vh', sm: '22vh', md: '25vh' },
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 15,
          background:
            'linear-gradient(135deg, #8a348e 0%, #423f88 50%, #333 100%)',
          rowGap: { xs: '10px', md: '20px' },
        }}
      >
        <PI.Typography
          variant="h3"
          component="div"
          fontWeight="bold"
          fontSize={{ xs: '22px', sm: '34px', md: '44px' }}
          color="#9E9E9E"
          display="flex"
          alignItems="center"
          columnGap={1}
          sx={{
            zIndex: 1,
          }}
        >
          Start
          <PI.Typography
            variant="h3"
            component="h5"
            className="animated-text"
            sx={{
              animation: isAnimating
                ? `${slideFromTop} 1s ease-out forwards`
                : 'none',
              color: currentCardText.color,
              fontSize: { xs: '22px', sm: '34px', md: '44px' },
              fontWeight: 'bold',
              zIndex: 1,
            }}
          >
            {currentCardText.text}
          </PI.Typography>
          Today
        </PI.Typography>
        <PI.Button
          variant="contained"
          size="medium"
          sx={{
            fontSize: { xs: '16px', sm: '18px', md: '20px' },
            // background:
            //   'linear-gradient(135deg, #ff7e5f 0%, #feb47b 50%, #ff6e7f 100%)',

            backgroundImage:
              'linear-gradient(to right, #314755 0%, #26a0da  51%, #314755  100%)',
            margin: '10px',
            padding: '8px 20px',
            textAlign: 'center',
            textTransform: 'uppercase',
            transition: '0.5s',
            backgroundSize: '200% auto',
            color: 'white',
            boxShadow: '0 0 2px #eee',
            borderRadius: '50px',
            display: 'block',
            border: 'none',

            '&:hover': {
              backgroundPosition:
                'right center' /* change the direction of the change here */,
              color: '#fff',
              textDecoration: 'none',
              border: 'none',
            },
          }}
        >
          Sign Up
        </PI.Button>
      </PI.Card>
      <HomeFooter />
    </PI.Box>
  );
};

export default HomeLayout;
