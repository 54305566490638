import React, { createContext, useState, useContext } from 'react';
import PI from '../core/CoreModules';

type ToastType = 'success' | 'error' | 'info' | 'warning';

interface ToastContextType {
  openToast: (message: string, type: ToastType) => void;
  closeToast: () => void;
}

// Create a context for Snackbar state and actions
const ToastContext = createContext<ToastContextType | undefined>(undefined);

// Custom hook to use Snackbar context
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

// Snackbar Provider component
export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<ToastType>('info');

  // Function to open the Snackbar
  const openToast = (message: string, type: ToastType = 'info') => {
    setToastMessage(message);
    setToastType(type);
    setToastOpen(true);
  };

  // Function to close the Snackbar
  const closeToast = () => {
    setToastOpen(false);
  };

  const action = (
    <React.Fragment>
      <PI.IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeToast}
      >
        <PI.CloseIcon fontSize="small" />
      </PI.IconButton>
    </React.Fragment>
  );

  return (
    <ToastContext.Provider value={{ openToast, closeToast }}>
      {children}
      <PI.Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={closeToast}
        // anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <PI.Alert
          onClose={closeToast}
          severity={toastType}
          sx={{ width: '100%' }}
          action={action}
        >
          {toastMessage}
        </PI.Alert>
      </PI.Snackbar>
    </ToastContext.Provider>
  );
};
