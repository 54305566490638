import * as React from 'react';
import IHeader from './IHeader';
import PI from '../../core/CoreModules';
import { VerifyTag } from '../../directive/VerifyTag';
import { Info } from './Info';
import { AccLimits } from './Acclimits';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const Identification = () => {
  const user: any = useSelector((state: RootState) => state.auth.user);

  return (
    <PI.Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
      <IHeader />
      <PI.Stack direction="row" spacing={1} p={5} alignItems="center">
        <PI.Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/2.jpg"
          sx={{
            width: { xs: 50, md: 66 },
            height: { xs: 50, md: 66 },
          }}
        />
        <PI.Stack direction="column" spacing={0}>
          <PI.Typography
            variant="h6"
            component="h6"
            sx={{
              fontSize: '24px',
              fontWeight: '600',
            }}
          >
            {user?.user_info?.first_name + ' ' + user?.user_info?.last_name}
          </PI.Typography>
          <VerifyTag
            text={
              user?.isEmailVerified && user?.isNumberVerified
                ? 'verified'
                : 'unVerified'
            }
            verified={user?.isEmailVerified && user?.isNumberVerified}
          />
        </PI.Stack>
      </PI.Stack>
      <PI.Divider />
      <AccLimits />
      <PI.Divider />
      <Info />
      <PI.Divider />
    </PI.Box>
  );
};

export default Identification;
