import React, { useEffect, useRef, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import PI from 'core/CoreModules';
import './p.scss';
import { useThemeContext } from 'theme/ThemeContextProvider';

const ParticleBackground = () => {
  const backgroundRef = useRef(null);
  const theme = useTheme();
  const { mode } = useThemeContext();
  const isMobile = PI.useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = PI.useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const getParticleCount = useCallback(() => {
    return isMobile ? 40 : isTablet ? 75 : 100;
  }, [isMobile, isTablet]);

  const getLineCount = useCallback(() => {
    return isMobile ? 20 : isTablet ? 35 : 50;
  }, [isMobile, isTablet]);

  const createParticles = useCallback(() => {
    const background: any = backgroundRef.current || {};
    if (!background) return;

    // Clear existing particles and lines
    background.innerHTML = '';

    const particleCount = getParticleCount();
    const lineCount = getLineCount();

    for (let i = 0; i < particleCount; i++) {
      const particle = document.createElement('div');
      particle.className = mode === 'dark' ? 'particle' : 'particle_dark';
      particle.style.width = particle.style.height = `${Math.random() * 5}px`;
      particle.style.left = `${Math.random() * 100}vw`;
      particle.style.top = `${Math.random() * 100}vh`;
      background.appendChild(particle);
    }

    for (let i = 0; i < lineCount; i++) {
      const line = document.createElement('div');
      line.className = mode === 'dark' ? 'line' : 'line_dark';
      line.style.width = `${Math.random() * 150 + 50}px`;
      line.style.height = '1px';
      line.style.left = `${Math.random() * 100}vw`;
      line.style.top = `${Math.random() * 100}vh`;
      line.style.transform = `rotate(${Math.random() * 360}deg)`;
      background.appendChild(line);
    }
  }, [getParticleCount, getLineCount]);

  useEffect(() => {
    createParticles();
  }, [createParticles, mode]);

  return <div ref={backgroundRef} className="particle-background" />;
};

export default ParticleBackground;
