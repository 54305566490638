import * as React from 'react';
import PI from '../core/CoreModules';
import { IconLabel } from './IconLabel';

export const OutlineCardInner = ({ ...props }) => {
  const { icon, title, description, showIconLabel, button } = props;

  return (
    <PI.Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'start', sm: 'center' }}
      justifyContent="space-between"
      spacing={{ xs: 2, sm: 3, md: 4 }}
    >
      <PI.Stack direction="row" alignItems="start" spacing={1} flexGrow={1}>
        {icon && icon}
        <PI.Stack direction="column" spacing={1} alignItems="start">
          <PI.Typography
            variant="h4"
            component="h5"
            sx={{
              fontSize: { xs: '14px', sm: '16px' },
              fontWeight: '600',
              lineHeight: { xs: '20px', sm: '24px' },
            }}
          >
            {title}
          </PI.Typography>
          <PI.Typography
            variant="body2"
            component="span"
            sx={{
              marginTop: 0,
              fontSize: { xs: '12px', sm: '14px' },
            }}
          >
            {description}
          </PI.Typography>
        </PI.Stack>
      </PI.Stack>
      <PI.Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        spacing={2}
        width="100%"
        sx={{ mt: { xs: 2, sm: 0 } }}
      >
        {showIconLabel && <IconLabel label="Off" done={false} />}
        {button}
      </PI.Stack>
    </PI.Stack>
  );
};
