import * as React from 'react';
import { AuthOuterLayout } from './AuthOuterLayout';
import SignUpCard from '../component/signup-card/SignUpCard';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import PI from '../core/CoreModules';

const SignUpLayout = () => {
  const appConfig = useSelector((state: RootState) => state.appConfig.config);

  return (
    <AuthOuterLayout>
      {appConfig && !appConfig?.register?.value ? (
        <PI.Typography
          variant="h6"
          component="div"
          textAlign="center"
          padding={2}
          color="#ffffff"
          sx={{
            backgroundColor: '#f44336',
            fontWeight: 'bold',
          }}
        >
          {appConfig?.register?.msg}
        </PI.Typography>
      ) : null}
      {appConfig && <SignUpCard config={appConfig} />}
    </AuthOuterLayout>
  );
};

export default SignUpLayout;
