import React from 'react';
import PI from '../../core/CoreModules';
import { Copyright } from '../../directive/AuthCardWrapper';
import { AuthCardWrapper } from '../../directive/AuthCardWrapper';
import { useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface ConfirmEmailProps {
  onCreateNew: (code: string) => void;
  isLoading: boolean;
}

const schema = yup.object().shape({
  code: yup
    .string()
    .required('Code is required')
    .matches(/^\d+$/, 'Code must be numeric'),
});

export default function ConfirmEmail({
  onCreateNew,
  isLoading,
}: ConfirmEmailProps) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data: { code: string }) => {
    onCreateNew(data.code);
  };

  return (
    <AuthCardWrapper title="Confirm Email" icon={<PI.ErrorOutlineIcon />}>
      <PI.Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3, minWidth: { xs: 'auto', md: '500px' } }}
      >
        <Controller
          name="code"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <PI.TextField
              {...field}
              required
              fullWidth
              id="code"
              label="Enter Code"
              autoComplete="off"
              size={isMobile ? 'small' : 'medium'}
              error={!!errors.code}
              helperText={errors.code?.message}
            />
          )}
        />
        <PI.Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || isLoading}
          sx={{
            mt: 3,
            mb: 2,
            height: { xs: '30px', md: '40px' },
            fontSize: { xs: '14px', md: '16px' },
          }}
        >
          {isLoading ? <PI.CircularProgress size={24} /> : 'Verify code'}
        </PI.Button>
        <PI.Grid container justifyContent="flex-end">
          <PI.Grid item>
            <PI.Link href="/login" variant="body2">
              Already have an account? Sign in
            </PI.Link>
          </PI.Grid>
        </PI.Grid>
      </PI.Box>
      {Copyright(null)}
    </AuthCardWrapper>
  );
}
