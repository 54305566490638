// src/api/api.ts

const API_BASE_URL = '/api/dashboard';

// Helper function for API calls
const fetchApi = async (endpoint: string, options: RequestInit = {}) => {
  const headers = {
    'Content-Type': 'application/json',
    credentials: 'include',
    ...options.headers,
  };

  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    ...options,
    headers,
  });

  // if (!response.ok) {
  //   throw new Error(`HTTP error! status: ${response.status}`);
  // }

  return response.json();
};

export const dashboardApi = {
  getChartData: () => fetchApi(`/chart`),

  getAppConfigData: () => fetchApi(`/appConfig`),

  joinWishList: (email: string) =>
    fetchApi('/join', {
      method: 'POST',
      body: JSON.stringify({ email }),
    }),
};
