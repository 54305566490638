import * as React from 'react';
import PI from '../../core/CoreModules';
import { OutlinedCard } from '../outlined-card/OutlinedCard';
import { SecurityCheckUp } from './components/SecurityCheckUp';
import { FactorAuth } from './components/FactorAuth';
import { AccountManagement } from './components/AccountManagement';

const Security = () => {
  return (
    <PI.Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
      <OutlinedCard title="Security Checkup">
        <SecurityCheckUp />
      </OutlinedCard>
      <OutlinedCard title="Two-Factor Authentication (2FA)">
        <FactorAuth />
      </OutlinedCard>
      <OutlinedCard title="Account Management">
        <AccountManagement />
      </OutlinedCard>
    </PI.Box>
  );
};

export default Security;
