import React from 'react';
import PI from '../core/CoreModules';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const Logo = () => {
  const navigateTo = useNavigate();
  const location = useLocation();

  const user = useSelector((state: RootState) => state.auth.user);

  const handleClick = () => {
    let path: string = '/';
    if (user) path = '/dashboard';
    navigateTo(path);
  };

  return (
    <PI.Box
      width={{
        xs: '60px',
        sm: '70px',
        md: location.pathname === '/' ? '75px' : '80px',
        lg: location.pathname === '/' ? '100px' : '80px',
      }}
      height={{
        xs: '60px',
        sm: '70px',
        md: location.pathname === '/' ? '75px' : '80px',
        lg: location.pathname === '/' ? '100px' : '80px',
      }}
      mr="10px"
      sx={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <img src="assets/pi_nt_logo.png" alt="logo" />
    </PI.Box>
  );
};

export default Logo;
