import './App.css';
import './index.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy, useEffect } from 'react';
import Box from '@mui/material/Box';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useThemeContext } from './theme/ThemeContextProvider';
import ForgotPasswordLayout from './layouts/ForgotPasswordLayout';
import ProtectedRoute from './component/protected-routes/ProtectedRoute';
import Identification from './component/identification/Identification';
import Security from './component/secuirty/Security';
import Loader from './loader/Loader';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import HomeLayout from './layouts/HomeLayout';
import LoginLayout from './layouts/LoginLayout';
import SignUpLayout from './layouts/SignUpLayout';
import ErrorLayout from './layouts/ErrorLayout';
import { dashboardApi } from 'api/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { setAppConfig } from './redux/appConfigSlicer';

// const HomePage = lazy(() => import('./layouts/HomeLayout'));
const OrdersPage = lazy(() => import('./layouts/OrdersLayout'));
const DashboardPage = lazy(() => import('./layouts/DashboardLayout'));
// const LoginPage = lazy(() => import('./layouts/LoginLayout'));
// const SignUpPage = lazy(() => import('./layouts/SignUpLayout'));
const DepositPage = lazy(() => import('./layouts/TransactionLayout'));
const WithdrawalPage = lazy(() => import('./layouts/TransactionLayout'));
const AccountPage = lazy(() => import('./layouts/AccountLayout'));
const SettingsPage = lazy(() => import('./layouts/SettingsLayout'));
// const ErrorPage = lazy(() => import('./layouts/ErrorLayout'));
const AdminPage = lazy(() => import('./layouts/AdminLayout'));
const AdminUserPage = lazy(() => import('./layouts/AdminUserLayout'));

const NETWORKS = {
  mainnet: {
    chainId: 56,
    name: 'Binance Smart Chain Mainnet',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com',
    rpcUrl: 'https://bsc-dataseed.binance.org/',
  },
  testnet: {
    chainId: 97,
    name: 'BNB Chain Testnet',
    currency: 'tBNB',
    explorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  },
};

function App() {
  const dispatch = useDispatch();
  const { theme, mode } = useThemeContext();
  useEffect(() => {
    const currentNetwork =
      process.env.REACT_APP_NETWORK === 'mainnet'
        ? NETWORKS.mainnet
        : NETWORKS.testnet;
    const initializeWeb3Modal = async () => {
      try {
        await createWeb3Modal({
          ethersConfig: defaultConfig({
            metadata: {
              name: 'My Website',
              description: 'My Website description',
              url: 'https://mywebsite.com',
              icons: ['https://avatars.mywebsite.com/'],
            },
            enableEIP6963: true,
            enableInjected: true,
            enableCoinbase: true,
            defaultChainId: currentNetwork.chainId,
          }),
          chains: [currentNetwork],
          projectId: '7b143d3136be6e8ccec21eb96ae15dbe',
          themeVariables: {
            '--w3m-font-family': 'Roboto, sans-serif',
          },
          includeWalletIds: [
            'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // MetaMask
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Coinbase
          ],
          enableOnramp: false,
        });
      } catch (error) {
        console.error('Failed to initialize Web3Modal:', error);
      }
    };

    initializeWeb3Modal();
  }, []);

  useEffect(() => {
    fetchChartData();
  }, []);

  const fetchChartData = async () => {
    try {
      const response = await dashboardApi.getAppConfigData();
      if (response.success) {
        dispatch(setAppConfig(response.config));
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
    } finally {
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box sx={{ width: '100%' }}>
        <Router>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<HomeLayout />} />
              <Route path="/login" element={<LoginLayout />} />
              <Route path="/register" element={<SignUpLayout />} />
              {/* <Route path="/trade/p2p" element={<OrdersPage />} /> */}

              <Route
                path="/forgot-password"
                element={<ForgotPasswordLayout />}
              />
              <Route path="*" element={<ErrorLayout />} />

              {/* Trade Routes */}
              <Route path="/trade" element={<OrdersPage />}>
                <Route path="p2p" element={<OrdersPage />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="orders" element={<OrdersPage />} />
                  <Route path="history" element={<OrdersPage />} />
                </Route>
              </Route>

              {/* Protected Routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/deposit" element={<DepositPage />} />
                <Route path="/withdrawal" element={<WithdrawalPage />} />
                <Route path="/account" element={<AccountPage />}>
                  <Route path="identification" element={<Identification />} />
                  <Route path="security" element={<Security />} />
                </Route>
                {/* <Route path="/trade/orders" element={<OrdersPage />} />
                <Route path="/trade/history" element={<OrdersPage />} /> */}
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/admin" element={<AdminPage />}>
                  <Route path="user/:id" element={<AdminUserPage />} />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
