import { useThemeContext } from '../theme/ThemeContextProvider';
import PI from '../core/CoreModules';

const NightModeToggle = () => {
  const { mode, toggleColorMode } = useThemeContext();

  return (
    <PI.IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
      {mode === 'dark' ? <PI.DarkModeIcon /> : <PI.LightModeIcon />}
    </PI.IconButton>
  );
};

export default NightModeToggle;
