import * as React from 'react';
import PI from '../../core/CoreModules';

export const AccLimits = () => {
  const data = [
    {
      name: 'Crypto Deposit & Withdrawal Limits',
      value: 'Unlimited',
    },
    {
      name: 'PI Deposit Limit',
      value: 'Unlimited',
    },
    {
      name: 'PI Withdrawal Limit',
      value: 'Unlimited',
    },
    {
      name: 'P2P Transaction Limits',
      value: 'Unlimited',
    },
    {
      name: 'Orders Posting Limit',
      value: 'Unlimited',
    },
  ];
  return (
    <PI.Box
      width="100%"
      maxWidth={{ xs: '100%', md: '60%' }}
      p={5}
      sx={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}
    >
      <PI.Typography
        sx={{
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '28px',
        }}
      >
        Account Limits
      </PI.Typography>
      <PI.Stack direction="column" spacing={1}>
        {data?.map((item: any, index: number) => (
          <PI.Stack key={index} direction="row" justifyContent="space-between">
            <PI.Typography
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
                color: 'rgb(132, 142, 156)',
              }}
            >
              {item.name}
            </PI.Typography>
            <PI.Typography
              sx={{
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {item.value}
            </PI.Typography>
          </PI.Stack>
        ))}
      </PI.Stack>
    </PI.Box>
  );
};
