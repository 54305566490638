import React, { useState } from 'react';
import PI from 'core/CoreModules';
import { dashboardApi } from 'api/dashboard';
import { useToast } from '../../context/ToastProvider';

export const WishList = () => {
  const { openToast } = useToast();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email: string): boolean => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);

    try {
      const data = await dashboardApi.joinWishList(email);

      if (data.error) {
        openToast(data.error, 'error');
        return;
      }

      openToast(data.message, 'success');

      setEmail('');
    } catch (error) {
      setError(
        error instanceof Error ? error.message : 'An unexpected error occurred'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (newEmail && !validateEmail(newEmail)) {
      setError('Please enter a valid email address');
    } else {
      setError('');
    }
  };

  return (
    <PI.Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        background:
          'linear-gradient(135deg, #8a348e 0%, #423f88 50%, #333 100%)',
        mb: 10,
        py: 10,
      }}
    >
      <PI.Card
        sx={{
          background: 'inherit',
          border: 'none',
          width: '100%',
          p: { xs: 2, md: 3, lg: 4 },
          boxShadow: 'none',
        }}
      >
        <PI.CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <PI.Typography
            variant="h3"
            fontWeight="bold"
            sx={{
              mb: 2,
              fontSize: { xs: '1.5rem', md: '2rem', lg: '2.5rem' },
              color: '#ffffff',
            }}
          >
            Coming Soon
          </PI.Typography>
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <PI.FormControl fullWidth margin="normal">
              <PI.Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
                justifyContent="center"
                spacing={2}
              >
                <PI.Stack
                  direction="column"
                  sx={{ mb: { xs: 2, md: 0 } }}
                  alignItems="start"
                >
                  <PI.Typography
                    variant="h6"
                    component="p"
                    sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}
                    fontWeight="bold"
                    color="#ffffff"
                  >
                    Join WishList
                  </PI.Typography>
                  <PI.Typography
                    variant="body2"
                    component="span"
                    sx={{ fontSize: { xs: '0.75rem', md: '0.875rem' } }}
                    fontWeight="bold"
                    color="#ffffff"
                  >
                    Be the first to know when we launch!
                  </PI.Typography>
                </PI.Stack>
                <PI.OutlinedInput
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleChange}
                  error={!!error}
                  disabled={isLoading}
                  endAdornment={
                    <PI.Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isLoading || !!error}
                      sx={{
                        height: '100%',
                        borderRadius: '40px',
                        backgroundColor: '#ffffff',
                        color: '#8a348e',
                        minWidth: '100px',

                        '&:hover': {
                          backgroundColor: '#f0f0f0',
                        },
                        '&:active': {
                          backgroundColor: '#e0e0e0',
                        },
                      }}
                    >
                      {isLoading ? 'Submitting...' : 'Submit'}
                    </PI.Button>
                  }
                  sx={{
                    borderRadius: '40px',
                    width: '100%',
                    height: '60px',
                    paddingRight: '0 !important',
                    maxWidth: { xs: '100%', md: '500px' },
                    borderWidth: '2px',
                    color: '#ffffff',
                    borderColor: !!error ? '#f44336' : '',
                    // '& .MuiOutlinedInput-notchedOutline': {
                    //   borderWidth: '1px',
                    // },
                  }}
                />
              </PI.Stack>
              {/* {error && <PI.FormHelperText error>{error}</PI.FormHelperText>} */}
            </PI.FormControl>
          </form>
        </PI.CardContent>
      </PI.Card>
    </PI.Box>
  );
};
