import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InnerObject {
  value: boolean;
  msg: string;
}

interface Fee {
  deposit: number;
  withdraw: number;
  sellOrder: number;
  buyOrder: number;
}

export interface IAppConfig {
  _id: string;
  withdraw: InnerObject;
  deposit: InnerObject;
  createOrder: InnerObject;
  login: InnerObject;
  register: InnerObject;
  allowKYC: boolean;
  fee: Fee;
  createdAt: Date;
  updatedAt: Date;
}

interface AppConfigState {
  config: IAppConfig | null;
  loading: boolean;
}

const initialState: AppConfigState = {
  config: null,
  loading: true,
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState,
  reducers: {
    setAppConfig: (state, action: PayloadAction<IAppConfig>) => {
      state.config = action.payload;
      state.loading = false;
    },
    updateAppConfig: (state, action: PayloadAction<Partial<IAppConfig>>) => {
      if (state.config) {
        state.config = { ...state.config, ...action.payload };
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearAppConfig: (state) => {
      state.config = null;
      state.loading = false;
    },
  },
});

export const { setAppConfig, updateAppConfig, setLoading, clearAppConfig } =
  appConfigSlice.actions;

export default appConfigSlice.reducer;
