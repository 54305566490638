import { AppThunk } from '../redux/store';
import { loginSuccess, logout, restoreUser } from '../redux/authSlice';

export const login =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data: any = await response.json();
      dispatch(loginSuccess(data.user));
      return data;
    } catch (error) {
      console.error(error);
      throw error;
      // Handle error (e.g., show an error message to the user)
    }
  };

export const logoutUser = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetch('/api/logout', {
      method: 'GET',
      credentials: 'include',
    });
    if (response.ok) {
      const data: any = await response.json();
      dispatch(logout());
      return data;
    } else {
      throw new Error('Token verification failed');
    }
  } catch (error) {
    console.error('Failed to verify token:', error);
    throw error;
  }
};

export const checkAuth = (): AppThunk => async (dispatch) => {
  try {
    const response = await fetch('/api/self', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (response.ok) {
      dispatch(restoreUser(data.user));
      return { success: true, user: data.user };
    } else {
      return { success: false, error: data.error };
    }
  } catch (error) {
    console.error('Error during authentication check:', error);
    return { success: false, error: 'Network error' };
  }
};

export const register = async (body: any) => {
  try {
    const response = await fetch('/api/register', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Registration failed');
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resetPassword = async (email: string, password: string) => {
  try {
    const response = await fetch(`/api/resetPassword`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'Failed to reset password');
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendOTP = async (email: string, reg: boolean) => {
  try {
    const url = reg
      ? `/api/generateOtp?email=${email}&registration=${reg}`
      : `/api/generateOtp?email=${email}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });

    const data = await response.json();

    if (data.error) {
      throw new Error(data.message || 'Failed to send OTP');
    }

    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const verifyOTP = async (email: string, code: string) => {
  try {
    const response = await fetch(`/api/verifyOtp?email=${email}&code=${code}`, {
      method: 'GET',
      credentials: 'include',
    });

    const data = await response.json();
    if (!response.ok) throw new Error(data.error || 'OTP verification failed');
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
