import * as React from 'react';
import PI from '../core/CoreModules';
import NightModeToggle from '../theme/NightModeToggle';
import { PiSquareLogo } from '../directive/PiSquareLogo';
import { useDispatch } from 'react-redux';
import { checkAuth } from '../api/user';
import { AppDispatch } from '../redux/store';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../redux/authSlice';
import Logo from '../directive/Logo';

export const AuthOuterLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchData();
  }, [dispatch, navigate]);

  const fetchData = async () => {
    dispatch(setLoading(true));
    try {
      const resp: any = await dispatch(checkAuth());
      if (resp && resp.success) {
        navigate('/dashboard');
      } else {
        // Handle unsuccessful authentication
        console.log('Authentication failed');
        // You might want to redirect to login page or show a message
      }
    } catch (err) {
      console.error('Error during authentication check:', err);
      // Handle error (e.g., show error message to user)
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <PI.Box
      sx={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        padding: '10px',
        background: '#8a348e',
      }}
    >
      <PI.Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ position: 'absolute', width: '100%', top: 0, padding: '30px' }}
      >
        <Logo />
        <NightModeToggle />
      </PI.Stack>
      <PI.Box
        sx={{
          width: '60%',
          height: '70%',
          position: 'absolute',
          top: '25%',
          right: '10%',
          background: '#593b8b',
          borderRadius: '70% 30% 30% 70% / 60% 40% 60% 40%',
        }}
      ></PI.Box>
      <PI.Box
        sx={{
          zIndex: '1',
        }}
      >
        {children}
      </PI.Box>
    </PI.Box>
  );
};
