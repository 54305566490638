import React, { useState } from 'react';
import { AuthOuterLayout } from './AuthOuterLayout';
import ForgotPassword from '../component/forgot-password/ForgotPassword';
import CreateNewPassword from '../component/forgot-password/CreateNewPassword';
import ConfirmEmail from '../component/forgot-password/ConfirmEmail';
import { sendOTP, verifyOTP, resetPassword } from '../api/user';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../context/ToastProvider';

enum Step {
  ForgotPassword,
  ConfirmCode,
  CreateNewPassword,
}

const ForgotPasswordLayout: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.ForgotPassword);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const navigate = useNavigate();
  const { openToast } = useToast();

  const handleStepChange = (step: Step) => {
    setCurrentStep(step);
  };

  const handleApiCall = async (apiFunction: () => Promise<void>) => {
    setIsLoading(true);
    try {
      await apiFunction();
    } catch (error) {
      console.error('API Error:', error);
      openToast(
        'An unexpected error occurred. Please try again later.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthOuterLayout>
      {currentStep === Step.ForgotPassword && (
        <ForgotPassword
          onEmailValidation={(inputEmail: string) =>
            handleApiCall(async () => {
              await sendOTP(inputEmail, false);
              setEmail(inputEmail);
              openToast('OTP sent successfully', 'success');
              handleStepChange(Step.ConfirmCode);
            })
          }
          isLoading={isLoading}
        />
      )}
      {currentStep === Step.ConfirmCode && (
        <ConfirmEmail
          onCreateNew={(code: string) =>
            handleApiCall(async () => {
              await verifyOTP(email, code);
              openToast('OTP verified successfully', 'success');
              handleStepChange(Step.CreateNewPassword);
            })
          }
          isLoading={isLoading}
        />
      )}
      {currentStep === Step.CreateNewPassword && (
        <CreateNewPassword
          onCodeConfirmation={(password: string) =>
            handleApiCall(async () => {
              await resetPassword(email, password);
              handleStepChange(Step.ForgotPassword);
              openToast('Password reset successfully', 'success');
              setTimeout(() => {
                navigate('/login');
              }, 2000);
            })
          }
          isLoading={isLoading}
        />
      )}
    </AuthOuterLayout>
  );
};

export default ForgotPasswordLayout;
