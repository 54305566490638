import * as React from 'react';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import PI from '../../core/CoreModules';
import { login } from '../../api/user';
import { AppDispatch } from '../../redux/store';
import { Copyright, AuthCardWrapper } from '../../directive/AuthCardWrapper';
import { useToast } from '../../context/ToastProvider';
import { IAppConfig } from '../../redux/appConfigSlicer';

interface LoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const validateEmail = (email: string) => {
  if (!email) {
    return 'Email is required';
  }
  if (!emailRegex.test(email)) {
    return 'Invalid email address';
  }
  return true;
};

const validatePassword = (password: string) => {
  if (!password) {
    return 'Password is required';
  }
  if (password.length < 8) {
    return 'Password must be at least 8 characters long';
  }
  return true;
};

interface LoginCardProps {
  config: IAppConfig;
}

export default function LoginCard({ config }: LoginCardProps) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { openToast } = useToast();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginFormData>({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    mode: 'onChange',
  });

  const onSubmit = async (data: LoginFormData) => {
    setIsLoading(true);
    try {
      const resp: any = await dispatch(login(data.email, data.password));
      if (resp.success) {
        openToast('Login successful!', 'success');
        navigate('/dashboard');
      } else {
        openToast(resp.error || 'Login failed. Please try again.', 'error');
      }
    } catch (error) {
      console.error('Login failed:', error);
      openToast(
        'An unexpected error occurred. Please try again later.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <AuthCardWrapper title="Login" icon={<PI.LockOutlinedIcon />}>
      <PI.Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1 }}
      >
        <Controller
          name="email"
          control={control}
          rules={{ validate: validateEmail }}
          render={({ field }) => (
            <PI.TextField
              {...field}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              size={isMobile ? 'small' : 'medium'}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{ validate: validatePassword }}
          render={({ field }) => (
            <PI.TextField
              {...field}
              margin="normal"
              required
              fullWidth
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              size={isMobile ? 'small' : 'medium'}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <PI.InputAdornment position="end">
                    <PI.IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <PI.VisibilityOff />
                      ) : (
                        <PI.VisibilityIcon />
                      )}
                    </PI.IconButton>
                  </PI.InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          name="rememberMe"
          control={control}
          render={({ field }) => (
            <PI.FormControlLabel
              control={
                <PI.Checkbox
                  {...field}
                  color="primary"
                  size={isMobile ? 'small' : 'medium'}
                />
              }
              label={
                <PI.Typography
                  variant="body1"
                  style={{ fontSize: isMobile ? '0.875rem' : '1rem' }}
                >
                  Remember me
                </PI.Typography>
              }
            />
          )}
        />
        <PI.Button
          type="submit"
          fullWidth
          variant="contained"
          // disabled={isLoading || !isValid || !config?.login?.value}
          disabled={isLoading || !isValid}
          sx={{
            mt: 3,
            mb: 2,
            height: { xs: '30px', md: '40px' },
            fontSize: { xs: '14px', md: '16px' },
          }}
        >
          {isLoading ? <PI.CircularProgress size={24} /> : 'Sign In'}
        </PI.Button>
        <PI.Grid container>
          <PI.Grid item xs>
            <PI.Link href="/forgot-password" variant="body2">
              Forgot password?
            </PI.Link>
          </PI.Grid>
          <PI.Grid item>
            <PI.Link href="/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </PI.Link>
          </PI.Grid>
        </PI.Grid>
        <Copyright />
      </PI.Box>
    </AuthCardWrapper>
  );
}
