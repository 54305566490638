import * as React from 'react';
import PI from '../core/CoreModules';

export const VerifyTag = ({ ...props }: any) => {
  const { text, verified } = props;
  return (
    <PI.Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      justifyContent="center"
      maxWidth="90px"
      sx={{
        borderRadius: '3px',
        padding: '3px',
        background: verified ? 'rgb(16, 40, 33)' : '#bdbdbd',
      }}
    >
      {verified && (
        <PI.DoneIcon sx={{ fontSize: '16px', color: 'rgb(14, 203, 129)' }} />
      )}
      <PI.Typography
        variant="body2"
        component="span"
        sx={{
          color: verified ? 'rgb(14, 203, 129)' : '#757575',
          fontWeight: 'bold',
        }}
      >
        {text}
      </PI.Typography>
    </PI.Stack>
  );
};
