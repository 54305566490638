import React, { useCallback, useEffect } from 'react';
import theme from './theme';
import PI from '../core/CoreModules';

export const useColorTheme = () => {
  const [mode, setMode] = React.useState<any>(
    localStorage.getItem('themeMode') || 'light'
  );

  const toggleColorMode = useCallback(() => {
    setMode((prevMode: any) => {
      const newMode = prevMode === 'dark' ? 'light' : 'dark';
      localStorage.setItem('themeMode', newMode);
      return newMode;
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  const modifiedTheme = React.useMemo(
    () =>
      PI.createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          mode,
        },
      }),
    [mode]
  );

  //   const modifiedTheme = React.useMemo(
  //     () => createTheme(getDesignTokens(mode)),
  //     [mode]
  //   );

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
  };
};
