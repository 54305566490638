import * as React from 'react';
import { AuthOuterLayout } from './AuthOuterLayout';
import LoginCard from '../component/login-card/LoginCard';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import PI from '../core/CoreModules';

const LoginLayout = () => {
  const appConfig = useSelector((state: RootState) => state.appConfig.config);
  return (
    <AuthOuterLayout>
      {appConfig && !appConfig?.login?.value ? (
        <PI.Typography
          variant="h6"
          component="div"
          textAlign="center"
          padding={2}
          color="#ffffff"
          sx={{
            backgroundColor: '#f44336',
            fontWeight: 'bold',
          }}
        >
          {appConfig?.login?.msg}
        </PI.Typography>
      ) : null}
      {appConfig && <LoginCard config={appConfig} />}
    </AuthOuterLayout>
  );
};

export default LoginLayout;
