import React, { useState } from 'react';
import PI from '../../core/CoreModules';
import { Copyright } from '../../directive/AuthCardWrapper';
import { AuthCardWrapper } from '../../directive/AuthCardWrapper';
import { useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface CreateNewPasswordProps {
  onCodeConfirmation: (password: string) => void;
  isLoading: boolean;
}

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('New password is required'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm password is required'),
});

export default function CreateNewPassword({
  onCodeConfirmation,
  isLoading,
}: CreateNewPasswordProps) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data: {
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    onCodeConfirmation(data.newPassword);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthCardWrapper title="Create New Password" icon={<PI.LockOutlinedIcon />}>
      <PI.Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3 }}
      >
        <PI.Grid container spacing={2}>
          <PI.Grid item xs={12}>
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <PI.TextField
                  {...field}
                  required
                  fullWidth
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="newPassword"
                  autoComplete="new-password"
                  size={isMobile ? 'small' : 'medium'}
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message}
                  InputProps={{
                    endAdornment: (
                      <PI.InputAdornment position="end">
                        <PI.IconButton
                          onClick={handleTogglePassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <PI.VisibilityOff />
                          ) : (
                            <PI.VisibilityIcon />
                          )}
                        </PI.IconButton>
                      </PI.InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </PI.Grid>
          <PI.Grid item xs={12}>
            <Controller
              name="confirmNewPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <PI.TextField
                  {...field}
                  required
                  fullWidth
                  label="Confirm New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="confirmNewPassword"
                  autoComplete="new-password"
                  size={isMobile ? 'small' : 'medium'}
                  error={!!errors.confirmNewPassword}
                  helperText={errors.confirmNewPassword?.message}
                />
              )}
            />
          </PI.Grid>
        </PI.Grid>
        <PI.Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || isLoading}
          sx={{
            mt: 3,
            mb: 2,
            height: { xs: '30px', md: '40px' },
            fontSize: { xs: '14px', md: '16px' },
          }}
        >
          {isLoading ? <PI.CircularProgress size={24} /> : 'Create'}
        </PI.Button>
        <PI.Grid container justifyContent="flex-end">
          <PI.Grid item>
            <PI.Link href="/login" variant="body2">
              Already have an account? Sign in
            </PI.Link>
          </PI.Grid>
        </PI.Grid>
      </PI.Box>
      {Copyright(null)}
    </AuthCardWrapper>
  );
}
