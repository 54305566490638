import * as React from 'react';
import { styled } from '@mui/material/styles';
import { AccordionProps } from '@mui/material/Accordion';
import { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import PI from '../../core/CoreModules';

const Accordion = styled((props: AccordionProps) => (
  <PI.MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <PI.MuiAccordionSummary
    expandIcon={<PI.ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  //   backgroundColor:
  //     theme.palette.mode === 'dark'
  //       ? 'rgba(255, 255, 255, .05)'
  //       : 'rgba(0, 0, 0, .03)',
  //   flexDirection: 'row-reverse',
  //   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //     transform: 'rotate(90deg)',
  //   },
  //   '& .MuiAccordionSummary-content': {
  //     marginLeft: theme.spacing(1),
  //   },
}));

const AccordionDetails = styled(PI.MuiAccordionDetails)(({ theme }) => ({
  //   padding: theme.spacing(2),
  //   borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQSCom({ ...props }) {
  const { list } = props;
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const renderAnswer = (answer: string) => {
    const lines = answer.split('\n').filter((line) => line.trim() !== '');
    const firstSentenceIndex = lines.findIndex((line) =>
      line.trim().match(/^\d+\./)
    );

    if (firstSentenceIndex === -1) {
      return (
        <PI.Typography
          variant="body1"
          component="p"
          fontSize={{ xs: '14px', sm: '16px', md: '18px' }}
          textAlign="left"
          paddingLeft={7}
        >
          {answer}
        </PI.Typography>
      );
    }

    const initialText = lines.slice(0, firstSentenceIndex).join(' ');
    const listItems = lines.slice(firstSentenceIndex);

    return (
      <>
        <PI.Typography
          variant="body1"
          component="p"
          fontSize={{ xs: '14px', sm: '16px', md: '18px' }}
          textAlign="left"
          paddingLeft={7}
          my={1}
        >
          {initialText}
        </PI.Typography>
        <PI.Typography
          component="ul"
          style={{
            textAlign: 'left',
          }}
        >
          {listItems.map((item, index) => (
            <PI.Typography
              key={index}
              component="li"
              style={{
                textAlign: 'left',
              }}
            >
              <PI.Typography
                variant="body1"
                component="p"
                fontSize={{ xs: '14px', sm: '16px', md: '18px' }}
                textAlign="left"
                paddingLeft={7}
              >
                {item.trim()}
              </PI.Typography>
            </PI.Typography>
          ))}
        </PI.Typography>
      </>
    );
  };

  return (
    <PI.Box width="100%" maxWidth="lg">
      <PI.Typography
        variant="h3"
        component="h5"
        fontWeight="bold"
        fontSize={{ xs: '28px', sm: '44px', md: '64px' }}
        color="#9E9E9E"
        sx={{
          zIndex: 1,
        }}
      >
        Frequently Asked Questions
      </PI.Typography>
      {list &&
        list.map((item: any, index: number) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <PI.Stack direction="row" alignItems="center" spacing={2}>
                <PI.Typography
                  variant="body1"
                  component="div"
                  display="flex"
                  justifyContent="center"
                  border="1px solid"
                  fontSize={{ xs: '16px', sm: '18px', md: '20px' }}
                  fontWeight="600"
                  borderRadius="15px"
                  padding={1}
                  width={35}
                >
                  {index + 1}
                </PI.Typography>
                <PI.Typography
                  variant="h6"
                  component="h1"
                  fontWeight={600}
                  fontSize={{ xs: '16px', sm: '18px', md: '20px' }}
                  textAlign="left"
                >
                  {item.question}
                </PI.Typography>
              </PI.Stack>
            </AccordionSummary>
            <AccordionDetails>{renderAnswer(item.answer)}</AccordionDetails>
          </Accordion>
        ))}
    </PI.Box>
  );
}
