// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, loading } = useSelector(
    (state: RootState) => state.auth
  );

  if (!isAuthenticated && !loading) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
