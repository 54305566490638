import * as React from 'react';
import PI from '../../core/CoreModules';
import { AuthCardWrapper, Copyright } from '../../directive/AuthCardWrapper';
import { useMediaQuery } from '@mui/material';
import { register, sendOTP, verifyOTP } from '../../api/user';
import { useToast } from '../../context/ToastProvider';
import { useNavigate } from 'react-router-dom';
import { IAppConfig } from '../../redux/appConfigSlicer';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  isEmailVerified: boolean;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  acceptTerms?: string;
  otp?: string;
}

const InitialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
  isEmailVerified: false,
};

interface SignUpCardProps {
  config: IAppConfig;
}

export default function SignUpCard({ config }: SignUpCardProps) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { openToast } = useToast();
  const navigateTo = useNavigate();
  const [values, setValues] = React.useState<FormValues>(InitialValues);
  const [errors, setErrors] = React.useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [showOTPInput, setShowOTPInput] = React.useState(false);
  const [otp, setOTP] = React.useState('');
  const [isVerifyingOTP, setIsVerifyingOTP] = React.useState(false);
  const [isSendingOTP, setIsSendingOTP] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const validateField = (name: string, value: string | boolean): string => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        return value ? '' : 'This field is required';
      case 'email':
        return /\S+@\S+\.\S+/.test(value as string)
          ? ''
          : 'Invalid email address';
      case 'password':
        return (value as string).length >= 8
          ? ''
          : 'Password must be at least 8 characters';
      case 'confirmPassword':
        return value === values.password ? '' : 'Passwords do not match';
      case 'acceptTerms':
        return value ? '' : 'You must accept the terms and conditions';
      default:
        return '';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    const newValue = name === 'acceptTerms' ? checked : value;

    setValues((prev) => ({ ...prev, [name]: newValue }));
    setErrors((prev) => ({ ...prev, [name]: validateField(name, newValue) }));
  };

  React.useEffect(() => {
    const formIsValid =
      Object.values(values).every((value) => value !== '') &&
      Object.values(errors).every((error) => error === '') &&
      values.acceptTerms &&
      values.isEmailVerified;
    setIsFormValid(formIsValid);
  }, [values, errors]);

  const handleSendOTP = async () => {
    if (!errors.email && values.email) {
      setIsSendingOTP(true);
      try {
        await sendOTP(values.email, true);
        setShowOTPInput(true);
        openToast('OTP sent successfully', 'success');
      } catch (error: any) {
        setErrors((prev) => ({
          ...prev,
          email: error.message || 'Failed to send OTP. Please try again.',
        }));
        openToast(error.message || 'Failed to send OTP', 'error');
      } finally {
        setIsSendingOTP(false);
      }
    }
  };

  const handleVerifyOTP = async () => {
    setIsVerifyingOTP(true);
    try {
      const isVerified = await verifyOTP(values.email, otp);
      if (isVerified) {
        setValues((prev) => ({ ...prev, isEmailVerified: true }));
        setErrors((prev) => ({ ...prev, otp: '' }));
        openToast('Email verified successfully', 'success');
      } else {
        setErrors((prev) => ({
          ...prev,
          otp: 'Invalid OTP. Please try again.',
        }));
      }
    } catch (error) {
      console.error('OTP verification failed:', error);
      setErrors((prev) => ({
        ...prev,
        otp: 'OTP verification failed. Please try again.',
      }));
      openToast('OTP verification failed', 'error');
    } finally {
      setIsVerifyingOTP(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isFormValid) return;

    setIsSubmitting(true);
    try {
      const response: any = await register(values);
      if (response.error) {
        openToast(response.error, 'error');
      } else {
        openToast(response.msg, 'success');
        openToast(
          'Registration successful! Redirecting to login...',
          'success'
        );
        setTimeout(() => {
          navigateTo('/login');
        }, 3000);
      }
    } catch (error) {
      console.error('Registration failed:', error);
      openToast('Registration failed. Please try again.', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthCardWrapper title="Register" icon={<PI.LockOutlinedIcon />}>
      <PI.Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 3 }}
      >
        <PI.Grid container spacing={2}>
          <PI.Grid item xs={12} sm={6}>
            <PI.TextField
              autoComplete="given-name"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              onChange={handleChange}
              value={values.firstName}
              error={!!errors.firstName}
              helperText={errors.firstName}
              autoFocus
              size={isMobile ? 'small' : 'medium'}
            />
          </PI.Grid>
          <PI.Grid item xs={12} sm={6}>
            <PI.TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              onChange={handleChange}
              value={values.lastName}
              error={!!errors.lastName}
              helperText={errors.lastName}
              size={isMobile ? 'small' : 'medium'}
            />
          </PI.Grid>
          <PI.Grid item xs={12}>
            <PI.TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChange}
              value={values.email}
              error={!!errors.email}
              helperText={errors.email}
              size={isMobile ? 'small' : 'medium'}
            />
            {!values.isEmailVerified && (
              <PI.Button
                onClick={handleSendOTP}
                disabled={
                  !values.email || isSendingOTP || !config?.register?.value
                }
                // disabled={!values.email || isSendingOTP}
                sx={{ mt: 1 }}
              >
                {isSendingOTP ? 'Sending OTP...' : 'Send OTP'}
              </PI.Button>
            )}
          </PI.Grid>
          {showOTPInput && !values.isEmailVerified && (
            <PI.Grid item xs={12}>
              <PI.TextField
                fullWidth
                id="otp"
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                error={!!errors.otp}
                helperText={errors.otp}
                size={isMobile ? 'small' : 'medium'}
              />
              <PI.Button
                onClick={handleVerifyOTP}
                disabled={!otp || isVerifyingOTP}
                sx={{ mt: 1 }}
              >
                {isVerifyingOTP ? 'Verifying...' : 'Verify OTP'}
              </PI.Button>
            </PI.Grid>
          )}
          <PI.Grid item xs={12}>
            <PI.TextField
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="new-password"
              onChange={handleChange}
              value={values.password}
              error={!!errors.password}
              helperText={errors.password}
              size={isMobile ? 'small' : 'medium'}
              InputProps={{
                endAdornment: (
                  <PI.InputAdornment position="end">
                    <PI.IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <PI.VisibilityOff />
                      ) : (
                        <PI.VisibilityIcon />
                      )}
                    </PI.IconButton>
                  </PI.InputAdornment>
                ),
              }}
            />
          </PI.Grid>
          <PI.Grid item xs={12}>
            <PI.TextField
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              id="confirmPassword"
              autoComplete="new-password"
              onChange={handleChange}
              value={values.confirmPassword}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              size={isMobile ? 'small' : 'medium'}
            />
          </PI.Grid>
          <PI.Grid item xs={12}>
            <PI.FormControlLabel
              control={
                <PI.Checkbox
                  name="acceptTerms"
                  color="primary"
                  checked={values.acceptTerms}
                  onChange={handleChange}
                />
              }
              label="I accept the terms and conditions"
            />
            {errors.acceptTerms && (
              <PI.Typography color="error" variant="caption">
                {errors.acceptTerms}
              </PI.Typography>
            )}
          </PI.Grid>
        </PI.Grid>
        <PI.Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            height: { xs: '30px', md: '40px' },
            fontSize: { xs: '14px', md: '16px' },
          }}
          // disabled={isSubmitting || !isFormValid || !config?.register?.value}
          disabled={isSubmitting || !isFormValid}
        >
          {isSubmitting ? 'Signing Up...' : 'Sign Up'}
        </PI.Button>
        <PI.Grid container justifyContent="flex-end">
          <PI.Grid item>
            <PI.Link href="/login" variant="body2">
              Already have an account? Sign in
            </PI.Link>
          </PI.Grid>
        </PI.Grid>
        {Copyright(null)}
      </PI.Box>
    </AuthCardWrapper>
  );
}
