import * as React from 'react';
import PI from '../../core/CoreModules';

interface OutlinedCardProps {
  title: any;
  children: React.ReactNode;
}

export const OutlinedCard: React.FC<OutlinedCardProps> = ({
  title,
  children,
}) => {
  return (
    <PI.Box
      m={{ xs: 0.5, sm: 1 }}
      p={{ xs: 2, sm: 3, md: 4 }}
      sx={{
        width: '98%',
        border: '1px solid #bdbdbd',
        borderRadius: '10px',
      }}
    >
      <PI.Typography
        sx={{
          fontSize: { xs: '20px', sm: '24px', md: '26px' },
          fontWeight: '600',
          lineHeight: { xs: '28px', sm: '32px', md: '34px' },
        }}
      >
        {title}
      </PI.Typography>
      <PI.Box mt={{ xs: 2, sm: 3 }}>{children}</PI.Box>
    </PI.Box>
  );
};
