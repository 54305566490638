import * as React from 'react';
import PI from '../../core/CoreModules';
import { styled } from '@mui/material/styles';
import { useThemeContext } from 'theme/ThemeContextProvider';

const MaterialUISwitch = styled(PI.Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#8a348e',
        ...theme.applyStyles('dark', {
          backgroundColor: '#8796A5',
        }),
        ...theme.applyStyles('light', {
          backgroundColor: '#8796A5',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'theme.platte.primary.main',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
    ...theme.applyStyles('dark', {
      backgroundColor: '#8a348e',
    }),
    ...theme.applyStyles('light', {
      backgroundColor: '#8a348e',
    }),
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
    ...theme.applyStyles('dark', {
      backgroundColor: '#8796A5',
    }),
    ...theme.applyStyles('light', {
      backgroundColor: '#8796A5',
    }),
  },
}));

function Copyright() {
  return (
    <PI.Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <PI.Link color="inherit" href="https://mui.com/">
        pinetworkhub.com
      </PI.Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </PI.Typography>
  );
}

const communityList = [
  {
    icon: <PI.FacebookIcon />,
    url: 'https://www.facebook.com/profile.php?id=61565179666022',
  },
  {
    icon: <PI.XIcon />,
    url: 'https://x.com/Pinetworkhub1',
  },
  {
    icon: <PI.InstagramIcon />,
    url: 'https://www.instagram.com/pinetworkhub1/',
  },
  {
    icon: <PI.YouTubeIcon />,
    url: '',
  },
  {
    icon: <PI.TelegramIcon />,
    url: '',
  },
  {
    icon: <PI.LinkedInIcon />,
    url: '',
  },
  {
    icon: '@',
    url: 'https://www.threads.net/@pinetworkhub1',
  },
];

// TODO remove, this demo shouldn't need to reset the theme.

export default function HomeFooter() {
  const { mode, toggleColorMode } = useThemeContext();
  return (
    <PI.Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <PI.Box
        sx={{
          width: '100%',
          maxWidth: '1400px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          px: 5,
          pt: 10,
        }}
      >
        <PI.Stack direction="column" alignItems="start" width="100%">
          <PI.Typography variant="body1" component="p" fontWeight="bold">
            Community
          </PI.Typography>
          <PI.Stack direction="row" flexWrap="wrap">
            {communityList?.map(
              (item, index) =>
                item.url !== '' && (
                  <PI.IconButton
                    key={index}
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      window.open(item.url, '_blank');
                    }}
                    color="secondary"
                  >
                    {item.icon}
                  </PI.IconButton>
                )
            )}
          </PI.Stack>
        </PI.Stack>
        <PI.Stack direction="row" width="100%" justifyContent="start">
          <PI.FormControlLabel
            control={
              <MaterialUISwitch
                sx={{ m: 1 }}
                onClick={toggleColorMode}
                checked={mode === 'dark'}
              />
            }
            label={
              <PI.Typography fontWeight="bold" variant="body1" ml={0}>
                Theme
              </PI.Typography>
            }
            labelPlacement="start"
            sx={{ ml: 0 }}
          />
        </PI.Stack>
        {/* <PI.Typography variant="body1">
          My sticky footer can be found here.
        </PI.Typography> */}
        <Copyright />
      </PI.Box>
    </PI.Box>
  );
}
