// src/redux/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface User {
  _id: string;
  name: string;
  username: string;
  email: string;
  isPiWalletConnect: boolean;
  pi_username: string;
  pi_uid: string;
  role: string;
  password: string;
  timestamp: Date;
  user_uid: string;
  user_info: {
    first_name?: string | null;
    last_name?: string | null;
    profile_url?: string;
    phone_no?: number | null;
    address?: string | null;
    country?: string | null;
    city?: string | null;
    state?: string | null;
    zip_code?: number | null;
  };
  isEmailVerified: boolean;
  isCloudVerified: boolean;
  isNumberVerified: boolean;
  isWalletCreated: boolean;
  wallet_address: string;
  wallet?: ICloudWallet;
  cloud_wallet_address: string;
}

interface ICurrencyBalance {
  balance: number;
  lockedBalance: number;
  availableBalance: number;
}

interface ICloudWallet {
  currencies: {
    PI: ICurrencyBalance;
    USDT: ICurrencyBalance;
  };
  createdAt: Date;
  updatedAt: Date;
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  loading: boolean; // Add loading state
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  loading: true, // Set initial loading state to true
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.loading = false; // Set loading to false when authenticated
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false; // Set loading to false on logout
    },
    restoreUser: (state, action: PayloadAction<User>) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.loading = false; // Set loading to false when user restored
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
      }
    },
    updateWallet: (state, action: PayloadAction<ICloudWallet>) => {
      if (state.user) {
        state.user.wallet = action.payload;
      }
    },
  },
});

export const { loginSuccess, logout, restoreUser, setLoading, updateUser,updateWallet } =
  authSlice.actions;

export default authSlice.reducer;
