import * as React from 'react';
import PI from '../../../core/CoreModules';
import { IconLabel } from '../../../directive/IconLabel';

export const SecurityCheckUp = () => {
  return (
    <PI.Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 1, md: 5 }}
      alignItems={{ xs: 'start', md: 'center' }}
      my={2}
    >
      <IconLabel label="Two-Factor Authentication (2FA)" done={false} />
      <IconLabel label="Identity Verification" done={true} />
    </PI.Stack>
  );
};
