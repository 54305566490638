import * as React from 'react';
import PI from '../../../core/CoreModules';
import { OutlineCardInner } from '../../../directive/OutlineCardInner';

export const AccountManagement = ({ ...props }) => {
  const data = [
    {
      name: 'Disable Account',
      icon: <PI.NoAccountsRoundedIcon sx={{ fontSize: '20px' }} />,
      des: 'Once the account is disabled, most of your actions will be restricted, such as logging in and trading. You can choose to unblock the account at any time. This action will not delete your account.',
      showIconLabel: false,
      showLabel: false,
      showAvatar: false,
      btn: {
        label: 'Disable',
        click: () => {
          console.log('disabled');
        },
      },
    },
    {
      name: 'Delete Account',
      icon: <PI.PersonRemoveRoundedIcon sx={{ fontSize: '20px' }} />,
      des: 'Please note that account deletion is irreversible. Once deleted, you will not be able to access your account or view your transaction history.',
      showIconLabel: false,
      showLabel: false,
      showAvatar: false,
      btn: {
        label: 'Delete',
        click: () => {
          console.log('delete');
        },
      },
    },
  ];
  return (
    <PI.Stack direction="column" spacing={4}>
      {data.map((item, index) => (
        <OutlineCardInner
          key={index}
          title={item.name}
          icon={item.icon}
          description={item.des}
          showIconLabel={item.showIconLabel}
          btnText={item.btn.label}
          showLabel= {item.showLabel}
          showAvatar= {item.showAvatar}
          handleClick={item.btn.click}
        />
      ))}
    </PI.Stack>
  );
};
