import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import PI from 'core/CoreModules';

const STLViewer = ({ show }: { show: boolean }) => {
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const controlsRef = useRef<OrbitControls | null>(null);
  const meshRef = useRef<THREE.Mesh | null>(null);
  const animationFrameId = useRef<number | null>(null);
  const lastUpdateTime = useRef<number>(0);
  const needsUpdate = useRef<boolean>(true);
  const theme = PI.useTheme();
  const isMobile = PI.useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = PI.useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const url = ['assets/three/pi_solo.stl'];

  useEffect(() => {
    if (!show || !mountRef.current) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(
      75,
      mountRef.current.clientWidth / mountRef.current.clientHeight,
      0.1,
      1000
    );
    cameraRef.current = camera;
    camera.position.z = 10;

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      preserveDrawingBuffer: true,
    });
    rendererRef.current = renderer;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.sortObjects = false;
    mountRef.current.appendChild(renderer.domElement);
    renderer.setSize(
      mountRef.current.clientWidth,
      mountRef.current.clientHeight
    );

    const controls = new OrbitControls(camera, renderer.domElement);
    controlsRef.current = controls;
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.target.set(0, 0, 0);
    controls.minDistance = 5;
    controls.maxDistance = 10;
    controls.maxPolarAngle = Math.PI / 1 - 0.1;
    controls.addEventListener('change', () => {
      needsUpdate.current = true;
    });

    const scaleObjectForScreenSize = () => {
      if (!meshRef.current) return;
      const screenWidth = window.innerWidth;
      let scaleFactor;

      if (screenWidth <= 480) {
        scaleFactor = 0.1;
      } else if (screenWidth <= 1024) {
        scaleFactor = 0.2;
      } else {
        scaleFactor = 0.3;
      }

      meshRef.current.scale.setScalar(scaleFactor);
      needsUpdate.current = true;
    };

    const onWindowResize = () => {
      if (
        !mountRef.current ||
        !cameraRef.current ||
        !rendererRef.current ||
        !controlsRef.current
      )
        return;

      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(width, height);
      controlsRef.current.update();
      scaleObjectForScreenSize();
      needsUpdate.current = true;
    };

    window.addEventListener('resize', onWindowResize);

    const loader = new STLLoader();
    loader.load(
      url[0],
      (geometry) => {
        const material = new THREE.MeshStandardMaterial({
          color: 0xffa726,
          emissive: 0x072534,
          roughness: 1,
          metalness: 0.6,
          side: THREE.DoubleSide,
          flatShading: true,
        });

        geometry.center();
        geometry.computeVertexNormals();
        geometry.computeBoundingSphere();

        geometry.computeBoundingBox();
        const boundingBox = geometry.boundingBox!;
        const currentWidth = boundingBox.max.x - boundingBox.min.x;
        const currentHeight = boundingBox.max.y - boundingBox.min.y;
        const scaleFactorX = (isMobile ? 8 : 10) / currentWidth;
        const scaleFactorY = (isMobile ? 8 : 10) / currentHeight;

        const mesh = new THREE.Mesh(geometry, material);
        meshRef.current = mesh;
        mesh.scale.set(scaleFactorX, scaleFactorY, scaleFactorY);
        mesh.rotation.set(-10, 10, -10);

        scene.add(mesh);

        // Add lights
        const lights = [];
        for (let i = 0; i < 6; i++) {
          lights[i] = new THREE.PointLight(0xffffff, 0.6, 0);
          scene.add(lights[i]);
        }

        lights[0].position.set(10, 10, 10);
        lights[1].position.set(-10, 10, 10);
        lights[2].position.set(10, -10, 10);
        lights[3].position.set(-10, -10, 10);
        lights[4].position.set(0, 0, 10);
        lights[5].position.set(0, 0, -10);

        const ambientLight = new THREE.AmbientLight(0x404040, 0.5);
        scene.add(ambientLight);

        const animate = (time: number) => {
          animationFrameId.current = requestAnimationFrame(animate);

          // Throttle updates to 60 FPS
          if (time - lastUpdateTime.current < 16.67) {
            return;
          }

          // Automatic rotation
          // if (meshRef.current) {
          //   meshRef.current.rotation.y += 0.002;
          //   needsUpdate.current = true;
          // }

          controlsRef.current?.update();

          // Only render if needed
          if (needsUpdate.current) {
            rendererRef.current?.render(scene, camera);
            needsUpdate.current = false;
          }

          lastUpdateTime.current = time;
        };

        animate(0);
      },
      undefined,
      (error) => {
        console.error('Error loading STL file:', error);
      }
    );

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
      window.removeEventListener('resize', onWindowResize);
      if (mountRef.current && rendererRef.current?.domElement) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
      rendererRef.current?.dispose();
    };
  }, [show]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={mountRef}
    />
  );
};

export default STLViewer;
