import React from 'react';
import PI from '../../core/CoreModules';
import { AuthCardWrapper } from '../../directive/AuthCardWrapper';
import { useMediaQuery } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface ForgotPasswordProps {
  onEmailValidation: (email: string) => void;
  isLoading: boolean;
}

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

export default function ForgotPassword({
  onEmailValidation,
  isLoading,
}: ForgotPasswordProps) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = (data: { email: string }) => {
    onEmailValidation(data.email);
  };

  return (
    <AuthCardWrapper title="Forgot Password ?" icon={<PI.ErrorOutlineIcon />}>
      <PI.Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3, minWidth: { xs: 'auto', md: '500px' } }}
      >
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <PI.TextField
              {...field}
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              size={isMobile ? 'small' : 'medium'}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
        <PI.Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || isLoading}
          sx={{
            mt: 3,
            mb: 2,
            height: { xs: '30px', md: '40px' },
            fontSize: { xs: '14px', md: '16px' },
          }}
        >
          {isLoading ? <PI.CircularProgress size={24} /> : 'Get code'}
        </PI.Button>
        <PI.Grid container justifyContent="flex-end">
          <PI.Grid item>
            <PI.Link href="/login" variant="body2">
              Already have an account? Sign in
            </PI.Link>
          </PI.Grid>
        </PI.Grid>
      </PI.Box>
    </AuthCardWrapper>
  );
}
