import * as React from 'react';
import PI from '../../core/CoreModules';

export const Info = () => {
  const data = [
    {
      name: 'Legal Name',
      value: 'Jhon Smith',
    },
    {
      name: 'Date of Birth',
      value: '1997-06-06',
    },
    {
      name: 'Address',
      value: 'New York, America (USA)',
    },
    {
      name: ' Identification Documents',
      value: 'English(Smartcard) National ID , 35**********55',
    },
    {
      name: 'Email Address',
      value: 'Unlimited',
    },
  ];
  return (
    <PI.Box
      width="100%"
      maxWidth={{ xs: '100%', md: '60%' }}
      p={5}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        position: 'relative',
      }}
    >
      <PI.Typography
        sx={{
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '28px',
        }}
      >
        Personal information
      </PI.Typography>
      <PI.Stack
        direction="column"
        spacing={1}
        sx={{
          filter: 'blur(5px)',
        }}
      >
        {data.map((item: any, index: number) => (
          <PI.Stack key={index} direction="row" justifyContent="space-between">
            <PI.Typography
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
                color: 'rgb(132, 142, 156)',
              }}
            >
              {item.name}
            </PI.Typography>
            <PI.Typography
              sx={{
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '20px',
              }}
            >
              {item.value}
            </PI.Typography>
          </PI.Stack>
        ))}
      </PI.Stack>
      <PI.Button
        variant="contained"
        color="primary"
        aria-label="kyc-verification"
        disabled={true}
        sx={{ width: '130px', position: 'absolute', top: '50%', left: '35%' }}
      >
        Verify
      </PI.Button>
    </PI.Box>
  );
};
