import * as React from 'react';
import PI from '../core/CoreModules';

interface AuthCardProps {
  title: any;
  icon: any;
  children: React.ReactNode;
}

export const Copyright = (props: any) => {
  return (
    <PI.Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: 5 }}
      {...props}
    >
      {'Copyright © '}
      <PI.Link color="inherit" href="https://pinetworkhub.com">
        PiNetworkHub
      </PI.Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </PI.Typography>
  );
};

export const AuthCardWrapper: React.FC<AuthCardProps> = ({
  title,
  icon,
  children,
}) => {
  return (
    <PI.Card sx={{ minWidth: 275, maxWidth: { xs: 320, sm: 450, md: 700 } }}>
      <PI.CardContent>
        <PI.Box
          sx={{
            my: { xs: 1, md: 8 },
            mx: { xs: 1, md: 4 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PI.Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>{icon}</PI.Avatar>
          <PI.Typography
            component="h1"
            variant="h5"
            sx={{ fontSize: { xs: '16px', md: '18px' }, fontWeight: 600 }}
          >
            {title}
          </PI.Typography>
          {children}
        </PI.Box>
      </PI.CardContent>
    </PI.Card>
  );
};
