import React from 'react';
import { useInView } from 'react-intersection-observer';
import PI from '../../core/CoreModules';
import { CARD_DATA } from './const';
import CustomCarousel from '../../directive/customSlider.tsx/CustomSlider';
import { useThemeContext } from '../../theme/ThemeContextProvider';

// interface HomeCardProps {
//   mode: any;
// }

export const HomeCards: React.FC = () => {
  const { mode } = useThemeContext();

  const getDirection = (index: number): 'row' | 'row-reverse' =>
    index % 2 === 0 ? 'row-reverse' : 'row';

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Reduced threshold
    rootMargin: '0px 0px -10% 0px', // Added rootMargin
    // onChange: (inView) => console.log(`Card inView:`, inView),
  });

  // const urls: any[] = [
  //   {
  //     url: 'assets/s1.png',
  //     alt: 'img-1',
  //   },
  //   {
  //     url: 'assets/s2.png',
  //     alt: 'img-2',
  //   },
  // ];

  return (
    <>
      {CARD_DATA.map((item, index) => {
        return (
          <PI.Stack
            key={index}
            width="100%"
            maxWidth="lg"
            justifyContent="space-between"
            direction={{
              xs: 'column-reverse',
              sm: 'column-reverse',
              md: getDirection(index),
            }}
            alignItems={{ xs: 'center', md: 'center' }}
            spacing={{ xs: 4, md: 8 }}
            ref={ref}
            sx={{ mb: { xs: 4, md: 8 } }}
          >
            {/* <PI.Slide direction="up" in={inView} timeout={500}> */}
            <PI.Card
              sx={{
                width: { xs: '100%', sm: '80%', md: '45%' },
                maxWidth: { xs: '100%', sm: '500px', md: '600px' },
                zIndex: '1',
                bgcolor: 'none !important',
                backgroundImage: 'none !important',
                boxShadow: 'none !important',
              }}
            >
              <PI.CardHeader
                avatar={
                  <PI.Avatar
                    aria-label="recipe"
                    alt={item.icon + index}
                    src={item.icon}
                    sx={{
                      bgcolor: mode === 'dark' ? '#fff' : '',
                      width: { xs: 50, md: 56 },
                      height: { xs: 50, md: 56 },
                      p: 0.7,
                    }}
                  >
                    {item.title}
                  </PI.Avatar>
                }
                title={
                  <PI.Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontSize: { xs: '1.25rem', sm: '1.50rem', md: '1.75rem' },
                      textAlign: 'left',
                      fontWeight: 'bold',
                    }}
                  >
                    {item.title}
                  </PI.Typography>
                }
                subheader={
                  <PI.Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{
                      fontSize: { xs: '0.8rem', sm: '0.875rem', md: '1rem' },
                      textAlign: 'left',
                    }}
                  >
                    {item?.subtitle || ''}
                  </PI.Typography>
                }
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '10px',
                  alignItems: 'start',
                }}
              />
              <PI.CardContent sx={{ textAlign: 'left' }}>
                <PI.Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' },
                  }}
                >
                  {item.description}
                </PI.Typography>
              </PI.CardContent>
            </PI.Card>
            {/* </PI.Slide> */}

            {/* <PI.Slide direction="up" in={inView} timeout={500}> */}
            {/* <PI.Card
                sx={{
                  zIndex: '1',
                  width: { xs: '100%', sm: '80%', md: '45%' },
                  maxWidth: { xs: '100%', sm: '500px', md: '600px' },
                }}
              > */}
            {item.img_urls.length > 1 ? (
              <CustomCarousel>
                {item.img_urls.map((image: any, idx: number) => (
                  <PI.CardMedia
                    key={idx}
                    component="img"
                    sx={{
                      // height: { xs: 200, sm: 250, md: 320 },
                      height: '320px !important',
                      objectFit: 'cover',
                    }}
                    image={image}
                    alt={`${item.title} - Image ${idx + 1}`}
                  />
                ))}
              </CustomCarousel>
            ) : (
              // <PI.CardMedia
              //   key={index}
              //   component="img"
              //   sx={{
              //     // height: { xs: 250, sm: 250, md: 320 },
              //     height: 320,
              //     objectFit: 'cover',
              //   }}
              //   image={item?.img_urls[0] || ''}
              //   alt={item.img_urls[0] + index}
              // />
              <div style={{ zIndex: 1 }}>
                <img
                  src={item?.img_urls[0] || ''}
                  alt={item.img_urls[0] + index}
                  height="450"
                  width="450"
                />
              </div>
            )}
            {/* </PI.Card> */}
            {/* </PI.Slide> */}
          </PI.Stack>
        );
      })}
    </>
  );
};
