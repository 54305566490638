import * as React from 'react';
import PI from '../../../core/CoreModules';
import { OutlineCardInner } from '../../../directive/OutlineCardInner';

export const FactorAuth = () => {
  const data = [
    {
      name: 'Email',
      icon: <PI.EmailRoundedIcon sx={{ fontSize: '20px' }} />,
      des: 'Use your email to protect your account and transactions.',
      showIconLabel: true,
      showLabel: false,
      showAvatar: false,
      btn: {
        label: 'Manage',
        click: () => {
          console.log('email');
        },
      },
    },
    {
      name: 'Phone Number',
      icon: <PI.PhoneInTalkRoundedIcon sx={{ fontSize: '20px' }} />,
      des: 'Use your phone number to protect your account and transactions.',
      showIconLabel: true,
      showLabel: false,
      showAvatar: false,
      btn: {
        label: 'Manage',
        click: () => {
          console.log('number');
        },
      },
    },
    {
      name: 'Login Password',
      icon: <PI.PasswordRoundedIcon sx={{ fontSize: '20px' }} />,
      des: 'Login password is used to log in to your account.',
      showIconLabel: false,
      showLabel: false,
      showAvatar: false,
      btn: {
        label: 'Manage',
        click: () => {
          console.log('password');
        },
      },
    },
  ];

  return (
    <PI.Stack direction="column" spacing={4}>
      {data.map((item, index) => (
        <OutlineCardInner
          key={index}
          title={item.name}
          icon={item.icon}
          description={item.des}
          showIconLabel={item.showIconLabel}
          btnText={item.btn.label}
          showLabel= {item.showLabel}
          showAvatar= {item.showAvatar}
          handleClick={item.btn.click}
        />
      ))}
    </PI.Stack>
  );
};
