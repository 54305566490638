import React from 'react';
import './loader.scss';

const Loader = () => {
  return (
    <div className="loader_main">
      {/* <div className="logo"> */}
      <div className="outer-ring"></div>
      <div className="inner-ring"></div>
      <div className="loader"></div>
      {/* </div> */}
    </div>
  );
};

export default Loader;
