// import { PaletteMode } from '@mui/material';
// import { blue, amber, deepOrange, grey } from '@mui/material/colors';
import { ThemeOptions } from '@mui/material/styles';

const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#8a348e',
    },
    mode: 'light', // Default mode
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight:'bold'
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 5, // Set your desired border radius here
        },
      },
    },
  },
};

// export const getDesignTokens = (mode: PaletteMode) => ({
//   palette: {
//     mode,
//     ...(mode === "light"
//       ? {
//           // palette values for light mode
//           primary: amber,
//           divider: amber[200],
//           text: {
//             primary: grey[900],
//             secondary: grey[800],
//           },
//         }
//       : {
//           // palette values for dark mode
//           primary: deepOrange,
//           divider: deepOrange[700],
//           background: {
//             default: deepOrange[900],
//             paper: deepOrange[900],
//           },
//           text: {
//             primary: "#fff",
//             secondary: grey[500],
//           },
//         }),
//   },
// });

export default theme;
