
export const CARD_DATA = [
  {
    icon: 'assets/wallet.png',
    title: 'Secure Wallet Integration',
    subtitle: 'Your safety is our priority',
    description:
      'Connect your Pi wallet securely to our platform. We prioritize the safety of your assets and personal information with state-of-the-art encryption and multi-factor authentication.',
    img_urls: ['assets/walletImage.png'],
  },
  {
    icon: 'assets/p2p.png',
    title: 'Fast and Reliable P2P Trading',
    subtitle: 'Pi to USDT in seconds',
    description:
      'Execute trades quickly and efficiently on our peer-to-peer marketplace. Our advanced matching algorithm ensures you get the best deals with minimal waiting time. experience user swift transaction confirmations for both Pi and USDT trades',
    img_urls: ['assets/tradingp2p.png'],
  },
  {
    icon: 'assets/transaction.png',
    title: 'Flexible Fund Management & Seamless Deposits and Withdrawals',
    subtitle: 'Full control at your fingertips',
    description:
      'Take charge of your digital assets with our comprehensive withdrawal and deposit system. Enjoy quick, hassle-free transfers between Pi and USDT, with real-time balance updates and transaction history tracking.',
    img_urls: ['assets/withdrawal.png'],
  },
  {
    icon: 'assets/securityIcon.png',
    title: 'Enhanced Security Measures',
    subtitle: 'Your protection is our priority',
    description:
      'We implement robust security protocols to safeguard your data and transactions. Our team monitors around the clock to ensure your safety and privacy.',
    img_urls: ['assets/secure.png'],
  },
  {
    icon: 'assets/support.png',
    title: '24/7 Customer Support',
    subtitle: "We're here when you need us",
    description:
      'Our dedicated support team is available round the clock to assist you with any queries or issues. Get prompt responses through email support.',
    img_urls: ['assets/support24.png'],
  },
  // {
  //   icon: 'assets/pi_light.png',
  //   title: 'Real-Time Market Data',
  //   description:
  //     'Stay informed with up-to-the-minute market trends, price charts, and trading volumes. Make data-driven decisions to maximize your trading potential.',
  //   img_urls: ['assets/s1.png'],
  // },
  // {
  //   icon: 'assets/pi_light.png',
  //   title: 'Advanced Trading Tools',
  //   subtitle: 'Empower your trading strategy',
  //   description:
  //     'Leverage cutting-edge analytics, real-time market data, and customizable charts to make informed trading decisions. Our platform offers limit orders, stop-loss features, and automated trading options to suit both novice and experienced traders.',
  //   img_urls: ['assets/s1.png'],
  // },
];

export const FAQS_DATA = [
  {
    question: 'What is cryptocurrency P2P trading?',
    answer:
      'Cryptocurrency P2P (peer-to-peer) trading is a decentralized method of exchanging digital currencies directly between users without the need for intermediaries. On Pi Network Hub, users can create buy and sell orders for Pi cryptocurrency, allowing them to trade directly with other users on the platform.',
  },
  {
    question: 'What services does Pi Network Hub offer?',
    answer: `Pi Network Hub is a comprehensive platform for Pi cryptocurrency enthusiasts, offering a range of services to enhance your Pi experience:

    1. P2P Trading: Buy and sell Pi directly with other users.
    2. Wallet Integration: Connect your Pi wallet for seamless transactions.
    3. Send and Receive: Transfer Pi to and from other users effortlessly.
    4. Transaction History: Keep track of all your Pi transactions.
    5. Order History: View your complete P2P trading history.
    6. Order Creation: Easily create buy or sell orders for Pi.

    Our platform is designed to provide a secure and user-friendly environment for all your Pi-related activities.`,
  },
  {
    question: 'How do I deposit Pi on Pi Network Hub?',
    answer: `To deposit Pi on Pi Network Hub, follow these steps:

    1. Log in to your Pi Network Hub account.
    2. Navigate to the 'Wallet' or 'Deposit' section.
    3. Select Pi as the cryptocurrency you wish to deposit.
    4. Connect your Pi wallet if you haven't already done so.
    5. Follow the on-screen instructions to initiate the transfer from your external Pi wallet to your Pi Network Hub wallet.
    6. Wait for the transaction to be confirmed on the Pi Network.

    Please ensure you've completed the necessary identity verification steps before making any deposits.`,
  },
  {
    question: 'How can I buy or sell Pi on Pi Network Hub?',
    answer: `To buy or sell Pi on Pi Network Hub:

    1. Log in to your account and navigate to the 'P2P Trade' section.
    2. Choose whether you want to buy or sell Pi.
    3. For buying: Browse available sell orders or create a buy order with your desired amount and price.
    4. For selling: Check existing buy orders or create a sell order specifying your terms.
    5. Once you find a suitable order, click on it to view details.
    6. Follow the instructions to complete the trade, which may involve transferring Pi or accepting payment through the agreed method.
    7. Confirm the transaction once completed.

    Always ensure you're trading with verified users and follow our platform's safety guidelines.`,
  },
  {
    question: 'How can I track Pi prices on Pi Network Hub?',
    answer: `Pi Network Hub offers several ways to track Pi prices:

    1. Order Book: Examine the P2P order book to see current buy and sell offers, giving you insight into market demand.
    2. Price Alerts: Set up price alerts to be notified when Pi reaches certain price levels.

    Remember that P2P prices may vary slightly from global market prices due to the nature of peer-to-peer trading.`,
  },
];

// 1. Price Chart: Visit our homepage or dedicated price section to view real-time Pi price charts.
// 2. Market Overview: Check our market overview page for current Pi prices, 24-hour trading volume, and market trends.
// 3. Order Book: Examine the P2P order book to see current buy and sell offers, giving you insight into market demand.
// 4. Price Alerts: Set up price alerts to be notified when Pi reaches certain price levels.
// 5. Historical Data: Access historical price data to analyze Pi's price movements over time.
