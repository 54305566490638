import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './theme/ThemeContextProvider';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ToastProvider } from './context/ToastProvider';
import STLViewer from './directive/Three';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ToastProvider>
          <ThemeContextProvider>
            <App />
          </ThemeContextProvider>
        </ToastProvider>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error('Failed to find the root element.');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
