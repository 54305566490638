/****************************************** Components *********************************************/

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Chip from '@mui/material/Chip';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import FormGroup from '@mui/material/FormGroup';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import Popover from '@mui/material/Popover';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';


/****************************************** theme *********************************************/

import { useMediaQuery, useTheme, createTheme } from '@mui/material';

/****************************************** Icons *********************************************/
import SvgIcon from '@mui/material/SvgIcon';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Cloud from '@mui/icons-material/Cloud';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SearchIcon from '@mui/icons-material/Search';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DoneIcon from '@mui/icons-material/Done';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import NoAccountsRoundedIcon from '@mui/icons-material/NoAccountsRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HistoryIcon from '@mui/icons-material/History';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorIcon from '@mui/icons-material/Error';
import LaunchIcon from '@mui/icons-material/Launch';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import StreamIcon from '@mui/icons-material/Stream';
import LinkIcon from '@mui/icons-material/Link';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const PI = {
  Box,
  Avatar,
  Container,
  Button,
  Typography,
  Stack,
  Divider,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Tooltip,
  MenuItem,
  MenuList,
  ListItemText,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Collapse,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemButton,
  Step,
  StepLabel,
  StepContent,
  Stepper,
  OutlinedInput,
  FormControl,
  Select,
  InputLabel,
  NativeSelect,
  InputBase,
  Radio,
  RadioGroup,
  FormLabel,
  FilledInput,
  InputAdornment,
  FormHelperText,
  Chip,
  SwipeableDrawer,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Pagination,
  Skeleton,
  Switch,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Zoom,
  Fab,
  Slide,
  Popover,
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,

  createTheme,
  useMediaQuery,
  useTheme,

  ArrowForwardIosSharpIcon,
  SvgIcon,
  ThumbUpIcon,
  AssignmentIcon,
  MenuIcon,
  AdbIcon,
  ListItemIcon,
  PersonAdd,
  Settings,
  Logout,
  ArrowDropDownIcon,
  Cloud,
  ContentCut,
  ContentCopy,
  ContentPaste,
  DashboardIcon,
  PersonIcon,
  FavoriteIcon,
  ShareIcon,
  ExpandMoreIcon,
  MoreVertIcon,
  LockOutlinedIcon,
  ErrorOutlineIcon,
  Brightness4Icon,
  Brightness7Icon,
  CloseIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  AddIcon,
  InboxIcon,
  DraftsIcon,
  SearchIcon,
  ArrowRightAltIcon,
  VisibilityOff,
  AccountCircle,
  DoneIcon,
  EmailRoundedIcon,
  PhoneInTalkRoundedIcon,
  PasswordRoundedIcon,
  CheckCircleRoundedIcon,
  CancelRoundedIcon,
  PersonRemoveRoundedIcon,
  NoAccountsRoundedIcon,
  PersonRoundedIcon,
  SettingsRoundedIcon,
  ContentCopyIcon,
  HistoryIcon,
  PeopleAltIcon,
  ExpandLess,
  ExpandMore,
  Alert,
  DarkModeIcon,
  LightModeIcon,
  PostAddIcon,
  EditIcon,
  DeleteIcon,
  InfoRoundedIcon,
  RefreshIcon,
  LoadingButton,
  ErrorIcon,
  LaunchIcon,
  AdminPanelSettingsIcon,
  HeadsetMicIcon,
  RemoveCircleIcon,
  StreamIcon,
  LinkIcon,
  FacebookIcon,
  XIcon,
  InstagramIcon,
  YouTubeIcon,
  TelegramIcon,
  LinkedInIcon
};

export default PI;
