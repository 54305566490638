import * as React from 'react';
import PI from '../core/CoreModules';

export const IconLabel = ({ ...props }) => {
  const { done, label } = props;
  return (
    <PI.Stack direction="row" spacing={{ xs: 1, sm: 2 }} alignItems="center">
      {done ? (
        <PI.CheckCircleRoundedIcon
          sx={{
            color: '#0ECB81',
            fontSize: { xs: '16px', sm: '18px', md: '20px' },
          }}
        />
      ) : (
        <PI.CancelRoundedIcon
          sx={{
            color: '#5E6673',
            fontSize: { xs: '16px', sm: '18px', md: '20px' },
          }}
        />
      )}
      <PI.Typography
        sx={{
          fontSize: { xs: '14px', sm: '16px', md: '18px' },
          fontWeight: 500,
          lineHeight: 1.5,
        }}
      >
        {label}
      </PI.Typography>
    </PI.Stack>
  );
};
